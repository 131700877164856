@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&family=Kaisei+Decol&display=swap');

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #111;
    color: #EEEEEE;
    font-size: 16px;
}

.property {
    height: 75vh;
}